import domReady from '@roots/sage/client/dom-ready';
import '../styles/app.scss';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

Swiper.use([Autoplay]);

// import {Common} from './routes/common';


/**
 * Application entrypoint
 */
domReady(async () => {
  // clone the logos to fill the screen with them
  const logos = document.querySelectorAll('.swiper--top .swiper-slide');
  if(logos.length > 0) {
    const logoContainer = document.querySelector('.swiper--top .swiper-wrapper');
    const logoContainerWidth = logoContainer.offsetWidth;
    const logoWidth = logos[0].offsetWidth;
    const logoCount = Math.ceil(logoContainerWidth / logoWidth) + 1;

    for (let i = 0; i < logoCount; i++) {
      logos.forEach((logo) => {
        const clone = logo.cloneNode(true);
        logoContainer.appendChild(clone);
      });
    }


    new Swiper('.swiper--top', {
      // Optional parameters
      // slidesPerView: 5,
      spaceBetween: 24,
      centeredSlides: true,
      speed: 4000,
      autoplay: {
        delay: 0,
      },
      loop: true,
      slidesPerView: 'auto',
      allowTouchMove: false,
      disableOnInteraction: true,
    });
  }

  // preloader
  const preloader = document.querySelector('#preloader');
  setTimeout(function() {
    preloader.style.transition = '1.5s';
  }, 500);
  setTimeout(function() {
    if (window.innerWidth < 768) {
      preloader.style.marginTop = '-250%';
    } else {
      preloader.style.marginTop = '-110%';
    }
  }, 1500);

  setTimeout(function() {
      preloader.style.display = 'none';
  }, 4500);

  // Menu
  const btnOpen = document.querySelector('#open_menu');
  const btnCloser = document.querySelector('#close_menu');
  const menu = document.querySelector('#full_menu');

  btnOpen.addEventListener('click', function() {
    menu.classList.toggle('is-active');
    btnOpen.classList.toggle('is-open');
    btnCloser.
    document.documentElement.style.overflow = 'hidden';
  });
  btnCloser.addEventListener('click', function() {
    menu.classList.toggle('is-active');
    btnOpen.classList.toggle('is-open');
    document.documentElement.style.overflow = 'auto';
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

